import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { transformTrend } from '../../utils/transforms';
import { TrendExplorerLayout } from '../../components/entities/trend-explorer-layout';

export default createGatsbyPageComponent('Trend', { layout: TrendExplorerLayout, transform: transformTrend });

export const trendQuery = graphql`
  query TrendQuery($id: ID!) {
    platform {
      trend(id: $id) {
        color { __typename id slug name value }
        id slug name path __typename outlineNumber
        description { text }
        openGraph { 
          title description { plain }
          image { url url2x thumbnails { large { url url2x } } }
        }
        banner {
          id
          thumbnail { url url2x } 
          image { url url2x } 
        }
        topics {
          id name path outlineNumber slug __typename 
          trendIntro { text }
          intro { text }
          color { id name value } 
          banner {
            id
            thumbnail { url url2x } 
            image { url url2x } 
          }
        }
        events {
          id name path slug title __typename
          type { id name slug }
          typeLabel
          trend { id color { id value } }
          openGraph { id image { id url url2x thumbnails { card { url url2x } } } }
          roles { id name slug 
            person {
              id name
              openGraph { image { thumbnails { bubble { url url2x } } } }
            }
          }
        }
        embeds {
          imageAssets {
            id
            slug
            image {
              id url url2x width height type 
            }
          }
          topics { 
            id path outlineNumber slug name __typename _schema { label pluralLabel }
            color { id value } 
            openGraph {
              title description { plain }
              image { url url2x }
            }
          }
        }
        sentimentAnalyses {
          id name slug
          images {
            id slug
            image { url url2x }
          }
          type { id name slug label color { id name value } }
          observations { 
            id name slug 
            description { text }
            topics {
              name slug path outlineNumber color { value }
            }
            subTopics {
              name slug path outlineNumber color { value }
            }
          }
        }
        pulseOfDiplomacyIntro { text }
        pulseOfImpactIntro { text }
        pulseOfImpactContent { text }
        pulseOfSocietyIntro { text }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        pulseOfSocietyContent { id text }
        whatDoPeopleSay {
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x } }
          intro { id text }
          content { id text }
          type { id name }
          types { id slug name }
          orderNumber
          embeds {
            imageAssets {
              id
              slug
              image {
                id url url2x width height type 
              }
            }
            topics { 
              id path outlineNumber slug name color { id value } 
              openGraph {
                title description { plain }
                image { url url2x }
              }
            }
          }
          trend { 
            id name slug 
            sentimentAnalyses {
              id name slug
              images {
                id slug
                image { url url2x }
              }
              type { id name slug label color { id name value } }
              observations { 
                id name slug 
                description { text }
                topics {
                  name slug path outlineNumber color { value }
                }
                subTopics {
                  name slug path outlineNumber color { value }
                }
              }
            }
          }
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          embeds {
            imageAssets {
              id
              slug
              image {
                id url url2x width height type 
              }
            }
            topics { 
              id path outlineNumber slug name color { id value } 
              openGraph {
                title description { plain }
                image { url url2x }
              }
            }
          }
        }
        whatDoPeopleDo {
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x } }
          intro { id text }
          content { id text }
          type { id name }
          types { id slug name }
          orderNumber
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          embeds {
            imageAssets {
              id
              slug
              image {
                id url url2x width height type
              }
            }
            topics { 
              id path outlineNumber slug name color { id value } 
              openGraph {
                title description { plain }
                image { url url2x }
              }
            }
          }
        }
        mediaAnalysis { 
          __typename id name path slug title subtitle pretitle 
          openGraph { image { url url2x } }
          intro { id text }
          content { id text }
          type { id name }
          types { id slug name }
          orderNumber
          people {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          moderators {
            __typename id name slug position
            photo { id url url2x }
            organisation { id name slug }
          }
          embeds {
            imageAssets {
              id
              slug
              image {
                id url url2x width height type
              }
            }
            topics { 
              id path outlineNumber slug name color { id value } __typename _schema { label pluralLabel }
              openGraph {
                title description { plain }
                image { url url2x }
              }
            }
          }
        }
        pages {
          __typename id name path slug title pretitle 
          _schema { label pluralLabel }
          subtitle
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
          type { id slug name }
          types { id slug name }
          typeLabel
          orderNumber
          trend { 
            id path name slug color { value }
            sentimentAnalyses {
              id name slug
              images {
                id slug
                image { url url2x }
              }
              type { id name slug label color { id name value } }
              observations { 
                id name slug 
                description { text }
                topics {
                  name slug path outlineNumber color { value }
                }
                subTopics {
                  name slug path outlineNumber color { value }
                }
              }
            }
          }
        }
      }
    }
  }
`;
