import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/gesda-types';
import { ContentTabs, ContentTabSpec, useView } from '@shapeable/ui';
import { AiUpperCaseIconGlyph } from '@shapeable/icons';
import { compact } from 'lodash';
import { TrendView } from '../../types';
const cls = classNames('trend-tabs-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendTabsLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendTabsLayoutDefaultProps: Omit<TrendTabsLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->


const TabsStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Tabs: styled(ContentTabs)<ContainerProps>`${TabsStyles}`,

};

export const TrendTabsLayout: Shapeable.FC<TrendTabsLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { color } = entity;

  const tabs: ContentTabSpec<TrendView>[] = compact([
    {
      id: 'radar-ai',
      icon: <AiUpperCaseIconGlyph />
    },
    {
      id: 'science',
      label: 'Pulse of Science',
    },
    {
      id: 'diplomacy',
      label: 'Pulse of Diplomacy',
    },
    {
      id: 'impact',
      label: 'Pulse of Impact',
    },
    {
      id: 'society',
      label: 'Pulse of Society',
    },
  ]);

  const { activeView, setActiveView } = useView<TrendView>(`trend-${entity.slug}`, 'science');
  
  return (
    <My.Tabs onTabClick={setActiveView} activeTab={activeView} items={tabs} colors={{ border: color?.value, hover: { text: color?.value } }} />
  )
};

TrendTabsLayout.defaultProps = TrendTabsLayoutDefaultProps;
TrendTabsLayout.cls = cls;