import React from 'react';
import styled, { css } from 'styled-components';
import { Classable, HasChildren, Shapeable, ContentNode as TContentNode } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { classNames } from '@shapeable/utils';
import { Trend } from '@shapeable/gesda-types';
import { CellLabel, ContentEntityIntro, ContentNode, DottedLine, EntityBanner, EntityGrid, NextPageLink, useLang, useTheme, useView } from '@shapeable/ui';
import { GptLayout } from '@shapeable/gpt';
import { isOpportunityEvent, TrendView } from '../../types';
import { EventCard } from './event-card';

const cls = classNames('trend-view-layout');

// -------- Types -------->

// -------- Props -------->

export type TrendViewLayoutProps = Classable & HasChildren & { 
  entity?: Trend;
};

export const TrendViewLayoutDefaultProps: Omit<TrendViewLayoutProps, 'entity'> = {
};

// -------- Child Component Props -------->

type ContainerProps = {

};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
});

const SectionStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(7)};
    display: flex;
    flex-direction: column;
    gap: ${theme.UNIT(4)};
  `,
});

const TabIntroStyles = breakpoints({
  base: css`
    font-weight: 300;
    font-size: ${15/14}em;
  `,
});

const ContentLabelStyles = breakpoints({
  base: css`
    color: ${theme.COLOR('text-mid')};
  `,
});

const DescriptionStyles = breakpoints({
  base: css`
    font-weight: 300;
    font-size: 1em;
  `,
});

const IntroStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(0)};
    font-weight: 300;
    font-size: ${15/14}em;
  `,
});

const TopicBannerStyles = breakpoints({
  base: css`
    font-size: 0.9em;
    font-weight: 300; 
    ${theme.TRANSITION('box-shadow')};
    &:hover {
      ${theme.HOVER_SHADOW()};
    }
  `,
});


const NextLinkStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(4)} 0;
  `,
});

const EventOpportunitiesGridStyles = breakpoints({
  base: css`
    .shp--card {
      background-color: ${theme.COLOR('bright')};
    }
  `,
});

const GptLayoutStyles = breakpoints({
  base: css`
    .shp--gpt-prompt {
      &:first-child {
        border-top: none;
      }
    };
  `,
});

const AssociatedPagesStyles = breakpoints({
  base: css`
    
  `,
});

// -------- Components -------->

const My = {
  Container: styled.div<ContainerProps>`${ContainerStyles}`,
    Section: styled.section`${SectionStyles}`,
    TabIntro: styled(ContentNode)`${TabIntroStyles}`,
    GptLayout: styled(GptLayout)`${GptLayoutStyles}`,

    TopicBanner: styled(EntityBanner)`${TopicBannerStyles}`,

    ContentLabel: styled(CellLabel)`${ContentLabelStyles}`,
    Description: styled(ContentNode)`${DescriptionStyles}`,
    ImpactContent: styled(ContentNode)`${DescriptionStyles}`,
    SocietyContent: styled(ContentNode)`${DescriptionStyles}`,
    TrendIntro: styled(ContentEntityIntro)`${IntroStyles}`,
    TopicCardIntro: styled(ContentNode)`${IntroStyles}`,
    AssociatedPages: styled(EntityGrid)`${AssociatedPagesStyles}`,

    EventOpportunitiesGrid: styled(EntityGrid)`${EventOpportunitiesGridStyles}`,

  NextLink: styled(NextPageLink)`${NextLinkStyles}`,
};

export const TrendViewLayout: Shapeable.FC<TrendViewLayoutProps> = (props) => {
  const { className, children, entity } = props;

  const { showView } = useView<TrendView>(`trend-${entity.slug}`, 'science');
  const t = useLang()

  const { pages = [], topics = [], color, pulseOfDiplomacyIntro, 
    pulseOfImpactIntro, pulseOfImpactContent, pulseOfSocietyIntro, 
    pulseOfSocietyContent, events = [] } = entity;

  const { description } = entity;
  const opportunities = events.filter(isOpportunityEvent);
  const hasPages = !!pages.length;
  const hasDiplomacyIntro = !!pulseOfDiplomacyIntro?.text;
  const hasImpactIntro = !!pulseOfImpactIntro?.text;
  const hasSocietyIntro = !!pulseOfSocietyIntro?.text;

  return (
    <My.Container className={cls.name(className)}>
      {
        showView('radar-ai') && 
        <My.Section>
          <My.GptLayout entity={entity} />
        </My.Section>
      }
      {
        showView('science') && 
        <>
          <My.Section>
            <My.Description entity={description as TContentNode} />
            <My.ContentLabel>{t('Associated Emerging Topics:')}</My.ContentLabel>
            {
              !!topics.length && topics.map(topic => { 
                const { id, intro, trendIntro } = topic;
                return ( 
                  <My.TopicBanner 
                    key={id}
                    maxHeight={2000}
                    entity={topic} 
                    variant='color' 
                    useBackgroundColor={false} 
                    useLinks={true}
                    entityValueProps={{ showImage: false, showOutlineNumber: true, outlineBubble: { numberSize: 0.6, showBorder: false, showImage: false }}}>
                    <My.TopicCardIntro entity={trendIntro?.text ? trendIntro : intro} />
                  </My.TopicBanner>
                )
              })
            }
          </My.Section>
          <My.Section>
          {
            hasPages && 
            <>
              <My.ContentLabel>{t('Related Content')}</My.ContentLabel>
              <My.AssociatedPages spacing={4} items={pages} />
            </>
          }
          </My.Section>
        </>
      }
      {
        showView('diplomacy') && 
        <My.Section>
          {
            hasDiplomacyIntro && 
            <My.TabIntro entity={pulseOfDiplomacyIntro} />
          }
          <My.EventOpportunitiesGrid 
            cardProps={{ showDescription: false }} 
            tabletAutoColumns={3}
            desktopAutoColumns={3}
            card={EventCard}
            items={opportunities} 
            spacing={4} 
          />
        </My.Section>
      }
      {
        showView('impact') && 
        <My.Section>
          {
            hasImpactIntro && 
            <My.TabIntro entity={pulseOfImpactIntro} />
          }
          {
            pulseOfImpactContent && 
            <My.ImpactContent entity={pulseOfImpactContent} />
          }
        </My.Section>
      }
      {
        showView('society') && 
        <>
        <My.Section>
          {
            hasSocietyIntro && 
            <My.TabIntro entity={pulseOfSocietyIntro} />
          }
        </My.Section>

        <My.Section>
         <My.SocietyContent entity={pulseOfSocietyContent} />
        </My.Section>
        </>
      }
      <DottedLine height={2} />
      <My.NextLink />
    </My.Container>
  )
};

TrendViewLayout.defaultProps = TrendViewLayoutDefaultProps;
TrendViewLayout.cls = cls;